body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.App {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.App-logo {
  max-height: 40px;
}

.App-header {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.8);
  height: 65px;
}
.App-header .bg-dark {
  background-color: rgba(33, 37, 41, 0.8) !important;
}
.App-footer {
  position: absolute;
  bottom: 0;
  z-index: 99;
  width: 100%;
  color: #fff;
  background-color: #282f39;
  font-size: 14px;
}

.App-headline {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/scientist-developer.4e1538bd.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  padding: 5%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.App-page {
  padding: 65px 0;
}
.page-content {
  padding: 100px 15px;
}
.form-feedback {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}
.headline {
  color: #ffffff;
  font-size: 28px;
}
.App-link {
  color: #61dafb;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
hr {
  max-width: 50px;
  border-width: 3px;
  border-color: #43c0bf;
}
hr.light {
  border-color: #fff;
}
section {
  padding: 8rem 0;
}
section.App-headline hr {
  margin-top: 30px;
  margin-bottom: 30px;
}
.section-heading {
  margin-top: 0;
}
.btn {
  border-radius: 300px;
}
.btn-xl {
  padding: 1rem 2rem;
}
.btn-primary {
  background-color: #43c0bf;
  border-color: #43c0bf;
}
.text-primary {
  color: #43c0bf !important;
}
.bg-primary {
  background-color: #43c0bf !important;
}
.text-faded {
  color: rgba(255, 255, 255, 0.7);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: hsla(180, 30%, 51%, 1);
  border-color: hsla(180, 30%, 51%, 1);
}
.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
}
a {
  color: #43c0bf;
  transition: all 0.2s;
}
.portfolio-box .portfolio-box-caption {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  color: #fff;
  background: rgba(67, 192, 191, 0.9);
  transition: all 0.2s;
}
.portfolio-detail .portfolio-box .portfolio-box-caption {
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.portfolio-detail .portfolio-box:hover .portfolio-box-caption {
  opacity: 0;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-category {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-category,
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-name {
  padding: 0 15px;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
}
.project-name {
  font-size: 1.5rem;
  font-weight: bold;
}
a:hover {
  color: #43c0bf;
}
.portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}
.page-title {
  font-weight: 700;
}
.filter {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  padding: 16px 8px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  font-weight: 500;
}
.filter.active,
.filter:hover {
  background-color: #43c0bf;
  color: #fff;
}

.item-section {
  margin-top: 50px;
}
.loading-bullet {
  color: #43c0bf;
}
.tag {
  padding: 5px;
  background: #52bfbf30;
  border: 1px solid #cecece;
  border-radius: 5px;
  margin-right: 5px;
}
@media (max-width: 700px) {
  .portfolio-box .portfolio-box-caption {
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
  html {
    font-size: 14px;
  }
  section.App-headline hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

